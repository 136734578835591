import React, { ChangeEvent } from 'react';
import './SettingsModal.scss';

type ScenarioKey =
  | 'real_estate_seller'
  | 'car_dealership_customer'
  | 'software_it_manager'
  | 'healthcare_patient'
  | 'insurance_claimant';

interface SettingsModalProps {
  onClose: () => void;
  scenario: ScenarioKey;
  onScenarioChange: (newScenario: ScenarioKey) => void;
}

export function SettingsModal({
  onClose,
  scenario,
  onScenarioChange,
}: SettingsModalProps) {
  const handleScenarioChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onScenarioChange(e.target.value as ScenarioKey);
  };

  return (
    <div data-component="SettingsModal" className="settings-modal-overlay">
      <div className="settings-modal-content">
        <h2>Settings</h2>
        <p>Pick the scenario you want the AI to roleplay as:</p>

        <label style={{ display: 'block', margin: '16px 0 8px' }}>
          AI Scenario
        </label>
        <select value={scenario} onChange={handleScenarioChange}>
          <option value="real_estate_seller">Real Estate Seller</option>
          <option value="car_dealership_customer">Car Dealership Customer</option>
          <option value="software_it_manager">Software IT Manager</option>
          <option value="healthcare_patient">Healthcare Patient</option>
          <option value="insurance_claimant">Insurance Claimant</option>
        </select>

        <button onClick={onClose} style={{ marginTop: '16px' }}>
          Close
        </button>
      </div>
    </div>
  );
}